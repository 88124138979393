<template>
  <div class="form">
<!--    <MyNavBar :title="title" :leftArrow="false"/>-->
      <van-nav-bar
          :title="title"
          :left-arrow="true"
          :fixed=true
          :placeholder=true
          @click-left="onClickLeft"
      />
    <div class="container">

      <div class="demo-item">
<!--        <p class="demo-desc">Form</p>-->
        <div class="demo-block" style="padding: 10px">
          <van-form v-if="infoShow">
              <van-field name="switch" label="报告公司">
                  <template #input v-if="projectInfo.bizCustom">
                      {{ projectInfo.bizCustom.name }}
                  </template>
              </van-field>
              <van-field name="switch" label="报告时间">
                  <template #input>
                      {{ projectInfo.time }}
                  </template>
              </van-field>
              <van-field name="switch" label="报告类型">
                  <template #input>
                      {{ evaluationCategoryFormat(projectInfo.evaluationCategory) }}
                  </template>
              </van-field>
              <van-field name="switch" label="项目编号">
                  <template #input>
                      {{ projectInfo.no }}
                  </template>
              </van-field>
<!--              <van-field name="switch" label="报告人">-->
<!--                  <template #input>-->
<!--                      {{ projectInfo.username }}-->
<!--                  </template>-->
<!--              </van-field>-->
          </van-form>
            <van-empty v-if="!infoShow" description="暂无报告~" />
        </div>
      </div>
    </div>


  </div>
</template>
<script>
  import { Notify } from 'vant';
  import { Toast } from 'vant';
  import MyNavBar from '@/components/MyNavBar.vue';
  import {get2} from "../api/api";


  export default {
    name: 'Form',
    components: {MyNavBar},
    data() {
      return {
          title: '暂无报告',
          form: {
              time:'2020-02-20',
              type:'安全评估',
              no:'AP--2024-041',
              username:'张三',
          },
          infoShow: true,
          projectId: null,
          projectInfo: {
              bizCustom:{}
          },
          biz_evaluation_category: [
              {id:1, name:'安全现状评价'},
              {id:2, name:'安全预评价'},
              {id:3, name:'安全验收评价'},
              {id:4, name:'安全评估'},
              {id:5, name:'检查'},
              {id:6, name:'其他'},
          ],
      };
    },
      created() {
          /*let projectId = this.$route.params.id;
          debugger
          console.log(projectId)*/
          this.projectId = this.$route.params.id;
          debugger
          if (this.projectId) {
              this.getInfo()
          } else {
              this.$router.push('/index');
          }
      },
    methods: {
        evaluationCategoryFormat(row) {
            let actions = [];
            Object.keys(this.biz_evaluation_category).some((key) => {
                if (this.biz_evaluation_category[key].id+'' == ('' + row)) {
                    actions.push(this.biz_evaluation_category[key].name);
                    return true;
                }
            })
            return actions.join('');
        },
        getInfo() {
            this.get2(this.projectId, "/biz/public/project/").then((res) => {
                console.log(res)
                if (res.code === 200) {
                    if (res.data) {
                        this.infoShow = true
                        // Toast.success('查询成功');
                        this.projectInfo = res.data
                        this.title = res.data.no
                    } else {
                        this.infoShow = false
                    }
                } else {
                    Notify(res.msg);
                }
            });
      },
        onClickLeft () {
            this.$router.push('/index');
        },
      onClick(index) {

        Notify('通知内容');
        // Notify({ type: 'primary', message: index });
      },
    },
  }
</script>
<style lang="less">

</style>
